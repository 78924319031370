import { HET_0310_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0310_render,
	yaw: -15.596,
	pitch: 29.89733
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -24.646,
		'yaw': -1.2925,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 25,
		}
	},
	//backward navigation
	{
		'pitch': -10,
		'yaw': 230.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 11,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene12 = {
	sceneId: '12',
	config: getConfig(customConfig)
};	

export default Scene12;