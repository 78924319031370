import { HET_0329_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0329_render,
	yaw: -7.5490,
	pitch: -123.526
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -13.934,
		'yaw': -104.0045,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 34,
		}
	},
	//backward navigation
	{
		'pitch': -15.4641,
		'yaw': 54.8557,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 28,
		}
	},
	//action 13: NOIR
	{
		'pitch': -41.0483,
		'yaw': -132.5429,
		'type': 'action',
		'createTooltipArgs': {
			'number': '14',
			'text': 'content.hoveritems.14',
			'param': 13
	
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene31 = {
	sceneId: '31',
	config: getConfig(customConfig)
};

export default Scene31;