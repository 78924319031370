import i18next from 'i18next';
import { initReactI18next }from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


const Languages = ['en','es','da','de','fr','nl','no','pl','ru','sv'];

export {Languages};

i18next
	//load translation from public/locales
	.use(Backend)
	//select user language
	//.use(LanguageDetector)
	.use(initReactI18next)
	
	.init({
		lng: window.location.pathname.substring(1),
		fallbackLng: 'en', 
		debug: true,
		whitelist: Languages,
	
		interpolation:{ escapeValue: false },	
	});

export default i18next;