import { HET_0316_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0316_render,
	yaw: -16.778,
	pitch: -94.091
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -32.3749,
		'yaw': -59.5442,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 19,
		}
	},
	//backward navigation
	{
		'pitch': -11.5102,
		'yaw': 87.4140,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 16,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene18 = {
	sceneId: '18',
	config: getConfig(customConfig)
};	

export default Scene18;