import { HET_0314_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0314_render,
	yaw: -16.1504,
	pitch: -133.7567
};

let customHotspots  = [
	//forward navigation
	{
		'pitch': -17.1504,
		'yaw': -133.7567,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 18,
		}
	},
	//backward navigation
	{
		'pitch': -9.4805,
		'yaw': 66.4469,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 25,
		}
	},
	//action 11: always sexy
	{
		'pitch': -4.39211,
		'yaw': -164.3544,
		'type': 'action',
		'createTooltipArgs': {
			'number': '11',
			'text': 'content.hoveritems.10',
			'param': 11
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene16 = {
	sceneId: '16',
	config: getConfig(customConfig),
};	

export default Scene16;