import { Asset5 } from 'assets/images';

const Item1 = {
	'hideCloseButton': true,
	'speechTitle': 'content.informationItem.1.speechTitle',
	'speechText': 'content.informationItem.1.speechText',
	'startButton': true,
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.1.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.1.contentText'
		},
		{
			'type': 'button',
			'param': 'content.informationItem.1.buttonText'
		},
	],
	'infoText': 'content.informationItem.1.infoText'
};
export default Item1;
