import { HET_0328_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0328_render,
	yaw: 0,
	pitch: 143.5
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -18,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 31,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 29,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene30 = {
	sceneId: '30',
	config: getConfig(customConfig)
};	

export default Scene30;