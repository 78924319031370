import { HET_0317_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0317_render,
	yaw: -16.2800,
	pitch: -121.5350
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -29.117,
		'yaw': -169.806,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 23,
		}
	},
	//backward navigation
	// {
	// 	'pitch': -20,
	// 	'yaw': 138.9,
	// 	'type': 'changeScene',
	// 	'text': 'Next destination',
	// 	'createTooltipArgs': {
	// 		'text': '1',
	// 		sceneId: 18,
	// 	}
	// },
	//action 12: perfect bra fitting
	{
		'pitch': -12.870,
		'yaw': -79.3392,
		'type': 'action',
		'createTooltipArgs': {
			'number': '12',
			'text': 'content.hoveritems.11',
			'param': 12
	
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene19 = {
	sceneId: '19',
	config: getConfig(customConfig)
};

export default Scene19;