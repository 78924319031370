const Item2 = {
	'speechTitle': 'content.informationItem.2.speechTitle',
	'speechText': 'content.informationItem.2.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.2.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.2.contentText'
		},
	],
	'infoText': 'content.informationItem.2.infoText'
};
export default Item2;
