import PubSub from 'pubsub-js';
import i18next from 'i18next';
// Hotspot callbacks

function onActionRequested(event){
	PubSub.publish('actionHotspot', event);
}

function onNavHotspotClick(event){
	PubSub.publish('changeScene', event.sceneId);
}

const createHotspot = function(props, args){
	props.addEventListener('click', function(){	onActionRequested(args);});
	const span = document.createElement('span');
	span.innerHTML = i18next.t(args.text);
	const number = document.createElement('number');
	number.innerHTML = args.number;
	props.appendChild(span);
	props.appendChild(number);
}; 

const createNavHotspot = function(props, args){
	props.addEventListener('click', function(){onNavHotspotClick(args);});
};

const NavHotspotConfig = {
	'cssClass': 'custom-nav-hotspot',
	'createTooltipFunc': createNavHotspot,
};

const HotspotConfig = {
	'cssClass': 'custom-act-hotspot',
	'createTooltipFunc': createHotspot,
};

function getHotspots(customHotspots){

	const newHotspots = [customHotspots.length];

	for(let i = 0; i < customHotspots.length; i++){
		switch(customHotspots[i].type){
		case 'changeScene':
			newHotspots[i] = {
				...NavHotspotConfig,
				...customHotspots[i]
			};
			break;
		case 'action':
			newHotspots[i] = {
				...HotspotConfig,
				...customHotspots[i]
			};
			break;
		}

		newHotspots[i].createTooltipArgs = {
			...newHotspots[i].createTooltipArgs,
		};
	}
	return newHotspots;
}

export { getHotspots };