import { HET_0332_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0332_render,
	yaw: -11.958,
	pitch: 179.503
};

let customHotspots = [
	//forward navigation
	{
		'pitch':  -25.2419,
		'yaw': -122.011,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 38,
		}
	},
	//backward navigation
	{
		'pitch': -11.467,
		'yaw': 97.365,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 31,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene34 = {
	sceneId: '34',
	config: getConfig(customConfig)
};	

export default Scene34;