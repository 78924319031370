import { HET_0326_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0326_render,
	yaw: -10.3483,
	pitch: -96.984
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': -21.2205,
		'yaw': -81.233,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 31,
		}
	},
	//backward navigation
	{
		'pitch': -38.792,
		'yaw': 92.579,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 27,
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene28 = {
	sceneId: '28',
	config: getConfig(customConfig),
};	

export default Scene28;