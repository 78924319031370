import Scene2 from './Scenes/scene2';
import React, { useEffect, useRef } from 'react';
import ReactPannellum, { addScene, loadScene, getAllScenes, isLoaded} from 'react-pannellum';
import { sceneList } from './Scenes/index';
import PubSub from 'pubsub-js';
import { useGlobalContext } from 'context/globalContext';
import { start } from 'repl';


const Pannellum = () => {

	const subscribeToken = useRef();

	useEffect(() => {
		//pubsub
		subscribeToken.current = PubSub.subscribe('changeScene', (name, val) => onNavigation(name, val));
		subscribeToken.current = PubSub.subscribe('actionHotspot', (name, val) => onAction(name, val));
		subscribeToken.current = PubSub.subscribe('startTour', (name, val) => onTourStart(name, val));

		return () => {
			if(subscribeToken.current){PubSub.unsubscribe(subscribeToken.current);} 
		};
	}, []);

	const {startSound, setTourStart, activeInformation, setActiveInformation, activePannellumScene, setActivePannellumScene} = useGlobalContext();
	
	//function for scene loaded check
	const isSceneLoaded = (sceneId) => {
		const scenes = getAllScenes();
		scenes.map((scene) => {
			if(scene[sceneId] != null){
				return true;
			}
		});
		return false;
	};

	const onChangeScene = (newScene) => {
		const scene =  sceneList[newScene - 1];
		// Check if scene is already loaded in
		if ( isSceneLoaded(scene.sceneId)) { 
			loadScene();
		} else{
			addScene(scene.sceneId, scene.config, () => loadScene(
				scene.sceneId, 
				scene.config.yaw ? scene.config.yaw : 0,
				scene.config.pitch ? scene.config.pitch : 0,
				120, 
				false
				
			));
		}
 
	};
	const onTourStart =(name, val) => {
		if(setTourStart){
			setTourStart(false);
			
		}
	};
	const onAction = (name, val) => {
		// Activate hotspot action
		if(setActiveInformation){
			setActiveInformation(val.param);
		} 	
	};

	useEffect(() => {
		if(activeInformation == 6 || activeInformation == 12){
			startSound && startSound(true); 
		}
	}, [activeInformation]);	

	const onNavigation = (name, val) => {
		// Load Pannellum Scene from value
		if(setActivePannellumScene){
			setActivePannellumScene(val);
		}
	};

	useEffect(() => {
		isLoaded() && activePannellumScene && onChangeScene(activePannellumScene);
	}, [activePannellumScene]);

    
	const style = {
		width: '100vw',
		height: '100vh',
		background: '#000000'
	};

	return (
		<>
			{activePannellumScene && activePannellumScene != -1 && (
				<ReactPannellum     
					config= {Scene2.config}
					imageSource={Scene2.config.imageSource}
					id = {Scene2.sceneId}
					yaw = {Scene2.config.yaw}
					pitch = {Scene2.config.pitch}
					sceneId = {Scene2.sceneId}
					style={style}>
				</ReactPannellum>

			)}
		</>

	);
};
    
export default Pannellum;
