import { HET_0325_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0325_render,
	yaw: 7.5264,
	pitch: -126.914
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': 9.2967,
		'yaw': -132.134,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 28,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 10,
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene27 = {
	sceneId: '27',
	config: getConfig(customConfig),
};	

export default Scene27;