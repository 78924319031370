const Item9 = {
	'speechTitle': 'content.informationItem.9.speechTitle',
	'speechText': 'content.informationItem.9.speechText',
	'content': [
		{
			'type': 'text',
			'param': 'content.informationItem.9.contentText'
		},

	],
	'infoText': ''
};
export default Item9;
