import { HET_0306_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0306_render,
	yaw: -8.24337,
	pitch: 176.869
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': -19.898,
		'yaw': 175.3331,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 10,
		}
	},
	//backward navigation
	{
		'pitch': -20.97817,
		'yaw': 1.0719,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 6,
		}
	},
	//action 8: introduce yourself
	{
		'pitch': -32.843,
		'yaw': 179.554,

		'type': 'action',
		'createTooltipArgs': {
			'number': '8',
			'text': 'content.hoveritems.9',
			'param': 8
		
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene8 = {
	sceneId: '8',
	config: getConfig(customConfig),
};	

export default Scene8;