// globalStyles.js
import React from 'react';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import theme from '../../theme';
import { StepsIcon, HoverStepsIcon, Star, HoverStar } from '../../assets/icons/index';
import { BebasNeue, SummerLovingSolid, ProximaNovaReg  } from 'assets/fonts/index';

const GlobalStyle = createGlobalStyle`


  @font-face {
      font-family: 'BebasNeue';
      src: url(${BebasNeue}) format("opentype");
  }

  @font-face {
      font-family: 'ProximaNovaReg';
      src: url(${ProximaNovaReg}) format("opentype");
  }

  @font-face {
      font-family: 'SummerLovingSolid';
      src: url(${SummerLovingSolid}) format("opentype");
  }
    
  body {
    margin: 0;
    padding: 0;
    background: white;
    /* font-family: 'BebasNeue'; */
  }
  .custom-nav-hotspot {
    height: 50px;
    width: 50px;
    background: ${theme.colors.pink_300};
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer!important;
    background-image: url(${StepsIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    user-select: none;
    &:hover{
		background-image: url(${HoverStepsIcon});
    border: 3px solid black;
    
	}
  }
  .custom-nav-hotspot:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    border: 3px solid ${theme.colors.pink_300};
  }

  .custom-act-hotspot {
    height: 50px;
    width: 50px;
    font-family: 'BebasNeue';
    background:  ${theme.colors.pink_300};
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer!important;
    user-select: none;
    text-align: center;
   line-height: 50px;
    background-image: url(${Star});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85%;
    user-select: none;
    &:hover{
		background-image: url(${HoverStar});
    border: 3px solid black;
}
    //animation: bounce 2s ease infinite;

    @keyframes bounce {
        70% { transform:translateY(0%); }
        80% { transform:translateY(-5%); }
        90% { transform:translateY(0%); }
        95% { transform:translateY(-7%); }
        97% { transform:translateY(0%); }
        99% { transform:translateY(-3%); }
        100% { transform:translateY(0); }
    }
    
  }
  .custom-act-hotspot:after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    border: 3px solid ${theme.colors.pink_300};
  }

  .custom-act-hotspot span {
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    color: #000;
    text-align: center;
    width: max-content;
    padding:  0px 12px 0px 12px;
    transform: translate(-50%, -100%);
    top: -15px;
    margin-left: 4px;
    border-radius: 20px;
    font-size: 1.5rem;
    cursor: default;
  }

  .custom-act-hotspot span::after{
    content: '';
    position: absolute;
    left:50%;
    top: 98%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid white;
    clear: both;
  }

  .custom-act-hotspot:hover span{
    visibility: visible;
  }
`;
 
export interface LayoutProps {
    children: React.ReactNode
}

const Layout = ({children}:LayoutProps) => {
    
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle/>
			{children}
		</ThemeProvider>
	);
};
    
export default Layout;