import { HET_0301_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0301_render,
	yaw: 0,
	pitch: 143.5
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -11.547,
		'yaw': 136.17,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 5,
		}
	},
	//backward navigation
	{
		'pitch': -27.6799,
		'yaw': -50.2091,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 2,
		}
	},

	//action 5: Signing
	{
		'pitch': 7.3973,
		'yaw': 98.8785,
		'type': 'action',
		'createTooltipArgs': {
			'number': '4',
			'text': 'content.hoveritems.3',
			'param': 5
	
		}
	},
	//action 6: genuine contact 
	{
		'pitch': 3.74106,
		'yaw': 162.1255,
		'type': 'action',
		'createTooltipArgs': {
			'number': '5',
			'text': 'content.hoveritems.6',
			'param': 6
		
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene3 = {
	sceneId: '3',
	config: getConfig(customConfig)
};	

export default Scene3;