import { Asset10 } from 'assets/images';

const Item10 = {
	'speechTitle': 'content.informationItem.10.speechTitle',
	'speechText': 'content.informationItem.10.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.10.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.10.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.10.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.10.contentText2'
		},
		{
			'type': 'image',
			'param': Asset10
		}
	],
	'infoText': 'content.informationItem.10.infoText'
};
export default Item10;
