import { HET_0318_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0318_render,
	yaw: 0,
	pitch: -181
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -20,
		'yaw': 158.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 21,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 19,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene20 = {
	sceneId: '20',
	config: getConfig(customConfig)
};

export default Scene20;