import React , { Suspense} from 'react';
import Pannellum from './components/Pannellum/Pannellum';
import Layout from './components/Layout';
import './App.css';
import GlobalContextProvider from 'context/globalContext';
import Input from './components/Input';
import Console from './components/Console';
import Information from 'components/Information';
import LanguageSelect from 'components/LanguageSelect';
import Introduction from 'components/Introduction';

function App() {
	return (
		<div className="canvas-container" >
			<Suspense fallback={ (<div>Loading...</div>)}>
				<Layout>
					<GlobalContextProvider>	
						<>
							<Introduction />
							{/* <LanguageSelect/> */}
							<Pannellum />
							<Console />
							<Input />
							<Information/>
						</>
					</GlobalContextProvider>
				</Layout>
			</Suspense>
		</div>
	);
}

export default App;
