import { Info1 } from 'assets/icons';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import { H1, P1 } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'context/globalContext';
import { InformationList } from './InformationItems';

const Window = styled.div.attrs({})`
    ${proportional}
    background: ${(props) => props.theme.colors.light_100 ? props.theme.colors.light_100 : 'red'};
    border-radius: 10px;
	position: relative;
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    &::-webkit-scrollbar {
    display: none;
    }
`;

const WindowContent = styled.div.attrs({padding:[15,15]})`
    ${proportional}

    & > div {
        margin: 0!important;
    }
`;

const Container = styled.div.attrs({})`
    ${proportional}
	height: 100%;
	display: flex;
    align-items: center;
`;

const Arrow = styled.div.attrs({})`
    ${proportional}
    content: '';
    position: relative;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid white; 
    
    width:0;
    height:0;
`;

const InfoIcon = styled.div.attrs({marginRight:[15,15]})`
    ${proportional}
    height: 100%;
    aspect-ratio: 1/1;

    background: ${(props) => props.theme.colors.pink_100 ? props.theme.colors.pink_100 : 'red'};
    border-radius: 50%;

	background-image: url(${Info1});
    background-position: center;
    background-size: cover;
`;


const Info = () => {

	const { t } = useTranslation();
	const { activeInformation }  = useGlobalContext();

	return (
		<Container>
			<InfoIcon/>
			<Arrow />
			<Window>
				<WindowContent>
					<P1>
						{activeInformation && t(InformationList[activeInformation-1].infoText)}					
					</P1>
				</WindowContent>
			</Window>
		</Container>
        
	);
};

export default Info;