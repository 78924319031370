const theme = {
	colors: {
		light_100: 'white',
		light_200: '#e2e2e2',
		dark_100: 'black',
		accent_1: '#009900',
		pink_100: '#FABFC0',
		pink_200: '#F287B7',
		pink_300: '#e81e75',
	},
  
	designFormats: ['428', '1920'],
	mediaQueries: {
		l_max: '@media (max-width: 640px)',
		l: '@media (min-width: 640px)',
		xl: '@media (min-width: 1920px)',
	},
};



export default theme;