const Item15 = {
	'speechTitle': 'content.informationItem.15.speechTitle',
	'speechText': 'content.informationItem.15.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.15.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.15.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.15.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.15.contentText2'
		}
	],
	'infoText': 'content.informationItem.15.infoText'
};
export default Item15;
