import Scene1 from './scene1';
import Scene2 from './scene2';
import Scene3 from './scene3';
import Scene4 from './scene4';
import Scene5 from './scene5';
import Scene6 from './scene6';
import Scene7 from './scene7';
import Scene8 from './scene8';
import Scene9 from './scene9';
import Scene10 from './scene10';
import Scene11 from './scene11';
import Scene12 from './scene12';
import Scene13 from './scene13';
import Scene14 from './scene14';
import Scene15 from './scene15';
import Scene16 from './scene16';
import Scene17 from './scene17';
import Scene18 from './scene18';
import Scene19 from './scene19';
import Scene20 from './scene20';
import Scene21 from './scene21';
import Scene22 from './scene22';
import Scene23 from './scene23';
import Scene24 from './scene24';
import Scene25 from './scene25';
import Scene26 from './scene26';
import Scene27 from './scene27';
import Scene28 from './scene28';
import Scene29 from './scene29';
import Scene30 from './scene30';
import Scene31 from './scene31';
import Scene32 from './scene32';
import Scene33 from './scene33';
import Scene34 from './scene34';
import Scene35 from './scene35';
import Scene36 from './scene36';
import Scene37 from './scene37';
import Scene38 from './scene38';


//import render_0301 from './render_0301';

// list of the scenes
const sceneList = [
	{...Scene1},{...Scene2},{...Scene3},{...Scene4},{...Scene5},
	{...Scene6},{...Scene7},{...Scene8},{...Scene9},{...Scene10},
	{...Scene11},{...Scene12},{...Scene13},{...Scene14},{...Scene15},
	{...Scene16},{...Scene17},{...Scene18},{...Scene19},{...Scene20},
	{...Scene21},{...Scene22},{...Scene23},{...Scene24},{...Scene25}, 
	{...Scene26},{...Scene27},{...Scene28},{...Scene29},{...Scene30},
	{...Scene31},{...Scene32},{...Scene33},{...Scene34},{...Scene35},
	{...Scene36},{...Scene37},{...Scene38},
];

export {sceneList};

export {
	Scene1, Scene2, Scene3, Scene4, Scene5, Scene6,
	Scene7, Scene8, Scene9, Scene10, Scene11, Scene12,
	Scene13, Scene14, Scene15, Scene16, Scene17, Scene18,
	Scene19, Scene20, Scene21, Scene22, Scene23, Scene24,
	Scene25, Scene26, Scene27, Scene28, Scene29, Scene30,
	Scene31, Scene32, Scene33, Scene34, 
	Scene35, Scene36, Scene37, Scene38
};