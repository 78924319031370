import { HET_0309_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0309_render,
	yaw:-15.406,
	pitch:  94.6917
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -40.1324,
		'yaw': 76.2421,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 12,
		}
	},
	//backward navigation
	{
		'pitch': -30.2061,
		'yaw': -120.7189,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 10,
		}
	},

];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene11 = {
	sceneId: '11',
	config: getConfig(customConfig)
};	

export default Scene11;