import { CrossIconHover, CrossIconNormal } from 'assets/icons';
import { useGlobalContext } from 'context/globalContext';
import styled from 'styled-components';
import { proportional } from 'util/proportional';

const CloseButton = styled.div.attrs({height:[,50], width:[,50], marginLeft:[25,50], marginTop:[25,50]})`

	${proportional};

    background: ${(props) => props.theme.colors.pink_300 ? props.theme.colors.pink_300 : 'red'};
    border-radius: 50%;
	transform: translateX(100%);
	position: absolute;
	top:0;
	right:0;
	z-index: 4;

	cursor: pointer;

	display:flex;

	&:hover > div{
		background-image: url(${CrossIconHover});
	}
    
`;

const Icon = styled.div.attrs({height:[,30], width:[,30]})`

	${proportional};

	background-image: url(${CrossIconNormal});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	cursor: pointer;
	margin: auto;
    
`;

const Close = () => {
    
	const {startSound, setActiveInformation} = useGlobalContext();

	const onCloseInformation = () => {
		setActiveInformation && setActiveInformation(0);
		startSound && startSound(false);
	};

	return (
		<CloseButton onClick={onCloseInformation}>
			<Icon/>
		</CloseButton>
	);
};

export default Close;