import { HET_0330_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0330_render,
	yaw: -7.5490,
	pitch: -123.526
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -103.1298,
		'yaw': -16.180,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 34,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 28,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene32 = {
	sceneId: '32',
	config: getConfig(customConfig)
};

export default Scene32;