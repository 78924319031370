import Item2 from './2';
import Item1 from'./1';
import Item3 from './3';
import Item4 from'./4';
import Item5 from './5';
import Item6 from'./6';
import Item7 from './7';
import Item8 from'./8';
import Item9 from './9';
import Item10 from'./10';
import Item11 from './11';
import Item12 from'./12';
import Item13 from'./13';
import Item14 from'./14';
import Item15 from'./15';

export type contentItem = {
	type:string,
	param:string,
};

export type informationItem  = {
	hideCloseButton?: boolean,
	speechTitle: string,
	speechText: string,
	startButton?: boolean,
	content:contentItem[],
	infoText:string,
};


// list of the information items
const InformationList:informationItem[] = [
	{...Item1}, {...Item2}, {...Item3}, {...Item4}, {...Item5}, {...Item6},
	{...Item7}, {...Item8}, {...Item9}, {...Item10}, {...Item11}, {...Item12},
	{...Item13}, {...Item14}, {...Item15}
];

export {InformationList};

export {
	Item1,
	Item2, 
	Item3,
	Item4, 
	Item5,
	Item6, 
	Item7,
	Item8, 
	Item9,
	Item10, 
	Item11,
	Item12, 
	Item13,
	Item14, 
	Item15
};