import { HET_0323_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0323_render,
	pitch: 49.0198,
	yaw: -4.9193
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': -7.7547,
		'yaw': 62.3178,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '2',
			sceneId: 16,
		}
	},
	//backward navigation
	{
		'pitch': -32.959,
		'yaw': -144.535,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 12,
		}
	},
	//action 10: HKMX
	{
		'pitch': 0.9582397,
		'yaw': 44.93518,
		'type': 'action',
		'createTooltipArgs': {
			'number': '10',
			'text': 'content.hoveritems.13',
			'param': 10
	
		}
	},

];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene25 = {
	sceneId: '25',
	config: getConfig(customConfig),

};	

export default Scene25;