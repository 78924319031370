import { HET_0313_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0313_render,
	yaw: -10,
	pitch: 43
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': -20,
		'yaw': 68.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 16,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 14,
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene15 = {
	sceneId: '15',
	config: getConfig(customConfig),
};	

export default Scene15;