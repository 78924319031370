import styled from 'styled-components';

const H1 = styled.div`
    font-family: 'SummerLovingSolid';
    font-size: 2.5rem;
    margin-bottom: 0.5rem ;
`;

const P1 = styled.div`
    font-family: 'ProximaNovaReg';
    font-size: 1.2rem;
    margin-bottom: 0.3rem ;
`;

const P2 = styled.div`
    font-family: 'BebasNeue';
    font-size: 2.5rem;
`;

export { H1, P1, P2};