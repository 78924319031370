import React, { useEffect } from 'react';
import styled from 'styled-components';
import { cover } from 'assets/renders';
import Information from 'components/Information';
import { useGlobalContext } from 'context/globalContext';
import { proportional } from 'util/proportional';
import { P1, P2 } from 'components/Typography';


const Container = styled.div`
    z-index: 5;
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url(${cover});
    background-position: center;
    background-size: cover;
	text-align: left;
`;




const Introduction = () => {
	const {TourStart, setActiveInformation} = useGlobalContext();


	useEffect(() => {
		setActiveInformation && setActiveInformation(1);
		return () => {
			console.log('cleanup');
		};
	}, []);

	// Render Function
	return (<>
		{TourStart && (
			<>
				<Container>
					<Information />
				</Container>
			</>
		)}
	</>
	);
};

export default Introduction;

