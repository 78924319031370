import { Sara1 } from 'assets/icons';
import { H1, P1 } from 'components/Typography';
import { useGlobalContext } from 'context/globalContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import { InformationList } from './InformationItems';

const Bubble = styled.div.attrs({})`
    ${proportional}
    background: ${(props) => props.theme.colors.light_100 ? props.theme.colors.light_100 : 'red'};
    border-radius: 10px;
	position: relative;
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    &::-webkit-scrollbar {
    display: none;
    }

`;

const BubbleContent = styled.div.attrs({padding:[15,15], paddingLeft:[15,25]})`
    ${proportional}
`;

const Container = styled.div.attrs({})`
    ${proportional}
	height: 100%;
	display: flex;
    align-items: center; 
`;
const Arrow = styled.div.attrs({})`
    ${proportional}
    content: '';
    position: relative;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-right:10px solid white; 
    margin:auto;
    width:0;
    height:0;
`;
const Sara = styled.div.attrs({marginRight:[15,15]})`
    ${proportional}
    height:100%;
    aspect-ratio: 1/1;
    background: ${(props) => props.theme.colors.pink_100 ? props.theme.colors.pink_100 : 'red'};
    border-radius: 50%;
	background-image: url(${Sara1});
    background-position: center;
    background-size: cover;
`;

const Speech = () => {

	const { t } = useTranslation();
	const { activeInformation }  = useGlobalContext();

	return(
		<Container>
			<Sara/>
			<Arrow/>
			<Bubble>
				<BubbleContent>
					<H1>{activeInformation && t(InformationList[activeInformation-1].speechTitle)}</H1>
					<P1>
						{activeInformation && t(InformationList[activeInformation-1].speechText)}					
					</P1>
				</BubbleContent>
			</Bubble>
		</Container>

	);
};

export default Speech;