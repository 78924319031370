import { Asset13 } from 'assets/images';

const Item13 = {
	'speechTitle': 'content.informationItem.13.speechTitle',
	'speechText': 'content.informationItem.13.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.13.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.13.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.13.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.13.contentText2'
		},
		{
			'type': 'image',
			'param': Asset13
		}
	],
	'infoText': 'content.informationItem.13.infoText'
};
export default Item13;
