import { Asset5 } from 'assets/images';

const Item5 = {
	'speechTitle': 'content.informationItem.5.speechTitle',
	'speechText': 'content.informationItem.5.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.5.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.5.contentText'
		},
		{
			'type': 'image',
			'param': Asset5
		}
	],
	'infoText': 'content.informationItem.5.infoText'
};
export default Item5;
