import { HET_0303_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0303_render,
	yaw: 0,
	pitch: -157
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -28,
		'yaw': 188.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 6,
		}
	},
	//backward navigation
	{
		'pitch': -12.1311,
		'yaw': 8.59505,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 3,
		}
	}
	,
	//action 7: sexy comes in all shapes
	{
		'pitch': 8.318709,
		'yaw': 157.1976,
		'type': 'action',
		'createTooltipArgs': {
			'number': '6',
			'text': 'content.hoveritems.12',
			'param': 7
		
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene5 = {
	sceneId: '5',
	config: getConfig(customConfig)
};	

export default Scene5;