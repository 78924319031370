import { Asset8 } from 'assets/images';

const Item8 = {
	'speechTitle': 'content.informationItem.8.speechTitle',
	'speechText': 'content.informationItem.8.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.8.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.8.contentText'
		},
		{
			'type': 'image',
			'param': Asset8
		}
	],
	'infoText': 'content.informationItem.8.infoText'
};
export default Item8;
