const Item4 = {
	'speechTitle': 'content.informationItem.4.speechTitle',
	'speechText': 'content.informationItem.4.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.4.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.4.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.4.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.4.contentText2'
		}
	],
	'infoText': 'content.informationItem.4.infoText'
};
export default Item4;
