import { H1, P1, P2 } from 'components/Typography';
import { useGlobalContext } from 'context/globalContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { proportional } from 'util/proportional';
import { InformationList } from './InformationItems';
//import * as sounds from '../../assets/sounds';

// Flex container to fill the children to the parent
const Container = styled.div.attrs({})`
    ${proportional}
    display:flex;
    flex-direction: column;


    background: ${(props) => props.theme.colors.pink_300 ? props.theme.colors.pink_300 : 'red'};
    border-radius: 10px;
    position:relative;
    max-height: 100%;
    overflow-y: auto;	

`;
const ImageComponent = styled.div.attrs({padding:[20,20]})`
    ${proportional}
	display: block;
  	margin-left: auto;
  	margin-right: auto;
  	max-width: 50%;
	max-height: 50%;
`;
const VideoComponent = styled.div.attrs({marginTop:[15,30]})`
    ${proportional}
	position: relative;
	padding:66.67% 0 0 0;
	& > iframe {
		position:absolute;
		inset:0;
		width:100%;
		height:100%;
	}
`;


const StartTourButton = styled.div.attrs({padding:[5,5]})`

	${proportional}

    background: ${(props) => props.theme.colors.light_100 ? props.theme.colors.light_100 : 'red'};
	border-radius: 8px;

	cursor: pointer;
	font-family: 'Bebasneue';
	color: ${(props) => props.theme.colors.pink_300 ? props.theme.colors.pink_300 : 'red'};
	font-size: larger;
	text-align: center;
	margin-top: 30px;
	z-index:1;
`;


// Wrapper to put padding around the content. (if put on the container it would break the higher up flex hierarchy).
const ContentWrapper = styled.div.attrs({paddingLeft:[15,30], paddingRight:[15,30], paddingTop:[10,20], paddingBottom: [15,30]})`
    ${proportional}
    color: white;
	& :last-child {
		margin-bottom: 0;
    }
`;

const CloseButton = styled(StartTourButton).attrs({})`
	@media (min-width: 640px) {
    	display: none;
  	}
`;

const Content = () => {

	const { setTourStart, setActiveInformation, startSound } = useGlobalContext();


	const onStartTour= () => {
		startSound && startSound(false);
		setTourStart && setTourStart(false);
		setActiveInformation && setActiveInformation(0);
	};

	const onCloseInformation = () => {
		setActiveInformation && setActiveInformation(0);
		startSound && startSound(false);
	};

	const { t } = useTranslation();
	const { activeInformation }  = useGlobalContext();
	const informationItem = activeInformation && {...InformationList[activeInformation-1]};
	const hideCloseButton = activeInformation && InformationList[activeInformation-1].hideCloseButton;

	return (
		<Container>
			<ContentWrapper>

				{informationItem && informationItem.content.map((contentItem, index)=> {
					switch(contentItem.type ){
					case 'title': 
						return(<H1 key={`contentItem-${index}-${contentItem.type}`} >{t(contentItem.param)}</H1>);
					case 'text': 
						return(<P1 key={`contentItem-${index}-${contentItem.type}`} >{t(contentItem.param)}</P1>);
					case 'image': 
						return(
							<ImageComponent>
								<img key={`contentItem-${index}-${contentItem.type}`} src={contentItem.param} width="100%" height="100%"/>
							</ImageComponent> );
					case 'video': 
						return(
							<VideoComponent>
								<iframe key={`contentItem-${index}-${contentItem.type}`} src={t(contentItem.param)}  height="100%" frameBorder="0" allow="autoplay;  picture-in-picture" allowFullScreen></iframe>
							</VideoComponent>
						);
					case 'button': 
						return(
							<StartTourButton onClick={onStartTour}><P2>{t(contentItem.param)}</P2></StartTourButton>
						);
					}
				}
				)}
				{ !hideCloseButton &&
					<CloseButton onClick={onCloseInformation}><P2>{t('general.closePanelButton')}</P2></CloseButton>
				}
				
			</ContentWrapper>
		</Container>
	);
};

export default Content;