import { HET_0321_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0321_render,
	yaw: -16.7588,
	pitch: 30.3484
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -0.18587,
		'yaw': -95.53358,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 10,
		}
	},
	// //backward navigation
	// {
	// 	'pitch': -20,
	// 	'yaw': 138.9,
	// 	'type': 'changeScene',
	// 	'text': 'Next destination',
	// 	'createTooltipArgs': {
	// 		'text': '1',
	// 		sceneId: 19,
	// 	}
	// },
	//action 7: sexy comes in all shapes
	{
		'pitch': -19.0552,
		'yaw': 68.4400,
		'type': 'action',
		'createTooltipArgs': {
			'number': '13',
			'text': 'content.hoveritems.12',
			'param': 7
	
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene23 = {
	sceneId: '23',
	config: getConfig(customConfig)
};	

export default Scene23;