import { HET_0324_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0324_render,
	pitch: 0,
	yaw: 0
};

let customHotspots  =	[
	//forward navigation
	{
		'pitch': -25.5,
		'yaw': 47.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '2',
			sceneId: 27,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 25,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene26 = {
	sceneId: '26',
	config: getConfig(customConfig),

};	

export default Scene26;