const Item3 = {
	'speechTitle': 'content.informationItem.3.speechTitle',
	'speechText': 'content.informationItem.3.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.3.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.3.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.3.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.3.contentText2'
		},

	],
	'infoText': 'content.informationItem.3.infoText'
};
export default Item3;
