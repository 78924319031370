import { proportional } from 'util/proportional';
import styled from 'styled-components';
import Content from './Content';
import Info from './Info';
import Close from './Close';
import { useGlobalContext } from 'context/globalContext';
import { InformationList } from './InformationItems';
import i18next from 'i18next';
import Speech from './Speech';

const InformationContainer = styled.div.attrs({width:[,800]})`
    ${proportional}

    ${(props) => props.theme.mediaQueries.l_max} { 
        width: 100vw;
    }

    position: absolute;
    left:0;
    top:0;
    height: 100vh;
    z-index:2;
    white-space: pre-line;

    // Debugging
    /* background-color: purple;
    opacity: 0.5; */
    
`;

const Rows = styled.div.attrs({})`
    ${proportional}
    height:100%;

    //flex
    display:flex;
    flex-direction:column;

    position:relative;
`;

const rowPadding = [25,40];

const SpeechRow = styled.div.attrs({height:[200,200], padding:[...rowPadding]})`

    ${proportional}
    flex: 0 0  auto;
    padding-bottom: 0!important;

    /* background-color: red; */
    
`;

const ContentRow = styled.div.attrs({padding:[...rowPadding]})`

    ${proportional}
    padding-bottom: 0!important;
    overflow-y: auto;
    flex: 1 1 auto;

`;

const InfoRow = styled.div.attrs({height:[100,100], padding:[...rowPadding]})`

    ${proportional}
    flex: 0 0 auto;
    /* flex-basis:100%; */

    /* background-color: blue; */
`;

const Backdrop = styled.div`

    position: absolute;
    inset:0;

    height:100vh;
    width:100vw;
    opacity: 0.4;
    background-color: black;

    z-index: 1;

`;




const Information = () => {
	const {activeInformation} = useGlobalContext();
	const hideCloseButton = activeInformation && InformationList[activeInformation-1].hideCloseButton;
	
	return (<>
		{activeInformation != 0 && (
			<>
				<InformationContainer>
					{ !hideCloseButton && <Close/>} 
					<Rows>
						<SpeechRow>
							<Speech/>
						</SpeechRow>
						{activeInformation && InformationList[activeInformation-1].content.length != 0 && 
						<ContentRow>
							<Content/>
						</ContentRow>
						}
						{activeInformation && InformationList[activeInformation-1].infoText != '' && 
							<InfoRow>
								<Info />
							</InfoRow>
						}
					</Rows>
				</InformationContainer>
				<Backdrop/>
			</>
		)} 
	</>
        
	);
};

export default Information;