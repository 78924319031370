import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'context/globalContext';
import { sceneList } from 'components/Pannellum/Scenes';
import { useTranslation } from 'react-i18next';

import i18next, { Languages } from 'components/Localization/index';
import { InformationList } from 'components/Information/InformationItems';
import { proportional } from 'util/proportional';

const ConsoleContainer = styled.div.attrs({width:[438,600]})`

	${proportional}

    z-index: 5;
    position: absolute;
    right:0;
    top:0;
    display: flex;
    justify-content: center;
    height: 20vh;
	text-align: left;
`;

const ConsoleRow = styled.div`
    display: flex;
    justify-content: left;
	text-align: left;
    align-items: center;
	margin-top: 10px;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
	button {
		float: right;
	}
`;

const ConsoleTitle = styled.div`
	font-size: 1.5rem;
	font-weight: bold;

`;

const ConsoleLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	padding-right: 10px;
`;

//#region Language select
const ConsoleLanguageSelect = () => {
	const { t } = useTranslation();

	const onChange =(event) =>{
		i18next.changeLanguage(event.target.value);
	};

	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.3')}</ConsoleLabel>
			<select name="language" onChange={onChange}>
				{Languages && Languages.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
			</select>
		</ConsoleRow>
	);
};
//#endregion

//#region Scene select
const ConsoleSceneSelect = () => {
	const { setActivePannellumScene, activePannellumScene } = useGlobalContext();
	const { t } = useTranslation();

	const [consoleActiveScene, setConsoleActiveScene] = useState<number>(activePannellumScene ? activePannellumScene : 1);
	const consoleRefActiveScene = useRef<HTMLSelectElement>(null);

	// On Change of Console Item
	useEffect(() => {

		setActivePannellumScene && setActivePannellumScene(consoleActiveScene);

	}, [consoleActiveScene]);


	// On Change of Active Pannellum Scene in context
	useEffect(() => {

		if(activePannellumScene != consoleActiveScene){
			activePannellumScene && setConsoleActiveScene(activePannellumScene);
		}

	}, [activePannellumScene]);			
	
	return(
		<ConsoleRow>
			<ConsoleLabel>{t('general.2')}</ConsoleLabel>
			<select onChange={(e) =>{setConsoleActiveScene(Number(e.target.value));}} ref={consoleRefActiveScene} value={consoleActiveScene}>
				{sceneList && sceneList.map((opt, index) => (<option key={`key-${opt}-${index + 1}`} label={String(index + 1)} value={index + 1}/>))}
			</select>
		</ConsoleRow>
	
	);
};
//#endregion

//#region Information select
const ConsoleInformationSelect = () => {
	const { t } = useTranslation();

	const { setActiveInformation, activeInformation } = useGlobalContext();
	const [consoleActiveInformation, setConsoleActiveInformation] = useState<number>(activeInformation ? activeInformation : 0);
	const consoleRefActiveInformation= useRef<HTMLSelectElement>(null);

	// On Change of Console Item
	useEffect(() => {

		setActiveInformation && setActiveInformation(consoleActiveInformation);
	
	}, [consoleActiveInformation]);
	
	// On Change of Active information in context
	useEffect(() => {
	
		if(activeInformation != consoleActiveInformation){
			activeInformation && setConsoleActiveInformation(activeInformation);
		}
	
	}, [activeInformation]);		

	return(

		<ConsoleRow>
			<ConsoleLabel>{t('general.4')}</ConsoleLabel>
			<select onChange={(e) =>{setConsoleActiveInformation(Number(e.target.value));}} ref={consoleRefActiveInformation} value={consoleActiveInformation}>
				<option label="0" value={0} key={'null-value-option'} />
				{InformationList && InformationList.map((opt, index) => (<option key={`key-${opt}-${index + 1}`} label={String(index + 1)} value={index + 1}/>))}
			</select>

		</ConsoleRow>
	);
};
//#endregion


const Console = () => {
	const {displayConsole} = useGlobalContext();
	const { t } = useTranslation();
	
	const [activeInterfaceItem, setActiveInterfaceItem] = useState('Scenes');
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const menuOptions = ['Language', 'Scenes', 'Information'];

	// Render Function
	return <> {displayConsole && (<ConsoleContainer>
		<ConsoleWrap>
			<ConsoleTitle>{t('general.1')}</ConsoleTitle>
			<ConsoleRow>
				<select onChange={(e) => setActiveInterfaceItem(e.target.value)} ref={selectTabRef} value={activeInterfaceItem}>
					{menuOptions && menuOptions.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
				</select>
			</ConsoleRow>
			{activeInterfaceItem === 'Scenes' && <ConsoleSceneSelect/>}
			{activeInterfaceItem === 'Language' && <ConsoleLanguageSelect/>}
			{activeInterfaceItem === 'Information' && <ConsoleInformationSelect/>} 
		</ConsoleWrap>
	</ConsoleContainer>)}
	</>;
	
};

export default Console;

