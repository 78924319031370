//global context: allows you to share information to any component,
//by storing it in a central place and allowing access to any component that requests it 
//(usually you are only able to pass data from parent to child via props
import React, {useEffect, useRef, useState} from 'react';
import { BackgroundAudio } from 'assets/sounds/index';


interface contextProviderProps { 
  children: JSX.Element, 
}
// context properties
type contextProps = {
	//sound props
	startSound: (val:boolean) => void,

	//introduction screen props
	setTourStart: (val:boolean) => void,
	TourStart: boolean,

	//dinamic content props
	setActiveInformation: (val:number) => void,
	activeInformation: number,

	// Pannellum Props
	setActivePannellumScene: (val:number) => void,
	activePannellumScene: number,

	// Console Props
	setDisplayConsole: (val: boolean) => void,
	onSetDisplayConsole: (val: boolean) => void,
	onToggleDisplayConsole: () => void,
	displayConsole: boolean,
}
// create context
const GlobalContext = React.createContext<Partial<contextProps>>({});

const GlobalContextProvider = ({ children }: contextProviderProps) => {
	//#region Introduction screen

	//#endregion
	const [TourStart, setTourStart] = useState<boolean>(true);
	//#region Dynamic Content

	const [activeInformation, setActiveInformation] = useState<number>(0);

	//#region Pannellum

	const [activePannellumScene, setActivePannellumScene] = useState<number>(2);
	
	//#endregion

	//#region Information
	const [displayInformation, setDisplayInformation] = useState<boolean>(true);

	//#endregion

	//#region Console

	const [displayConsole, setDisplayConsole] = useState<boolean>(false);

	const onSetDisplayConsole = (val) => {
		setDisplayConsole(val);
	};

	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};

	const audioComponent = useRef<HTMLAudioElement>(null);

	const startSound = (state) => {
		if(audioComponent.current){
			audioComponent.current.play();
			audioComponent.current.loop = true;
			audioComponent.current.muted = state;
		}
	};



	useEffect(() => {
		console.log(displayConsole);
	}, [displayConsole]);

	//#endregion
	
	
	// Passed Values

	const passedValues = {
		//Introduction
		TourStart,

		//Information
		displayInformation,
		activeInformation, 

		// Pannellum
		activePannellumScene,

		// Console
		displayConsole

	};
	
	// Passed Functions/Methods

	const passedFunctions = {
		//Introduction
		setTourStart,

		//Information
		setDisplayInformation,
		setActiveInformation,

		// Pannellum
		setActivePannellumScene,

		// Console
		setDisplayConsole,
		onSetDisplayConsole,
		onToggleDisplayConsole,

		//sound
		startSound,

	};
	
	// Render
	return (		
		<GlobalContext.Provider value={{ ...passedValues, ...passedFunctions }}>
			{children}
			<audio ref={audioComponent}>
				<source src={BackgroundAudio}/>
			</audio>
		</GlobalContext.Provider>
	);
};

const useGlobalContext = () => React.useContext(GlobalContext);

export {useGlobalContext, GlobalContext};

export default GlobalContextProvider;