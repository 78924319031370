import { HET_0308_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0308_render,
	yaw: 4.1645,
	pitch: 59.2149
};

let customHotspots = [
	//forward navigation sport sectie
	{
		'pitch': -13.28682,
		'yaw': 19.46988,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 11,
		}
	},
	//forward navigation sexy sectie
	{
		'pitch': -19.3490,
		'yaw': 71.23073,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 27,
		}
	},
	//backward navigation
	{
		'pitch': -36.194,
		'yaw': -100.734,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 8,
		}
	},
	//action 9: sporty or sexy
	{
		'pitch': -8.67773,
		'yaw': 45.5138,
		'type': 'action',
		'createTooltipArgs': {
			'number': '9',
			'text': 'content.hoveritems.8',
			'param': 9
	
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene10 = {
	sceneId: '10',
	config: getConfig(customConfig)
};

export default Scene10;