import { HET_0320_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0320_render,
	yaw: 0,
	pitch: -166.5
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -28,
		'yaw': 188.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 17.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 23,
		}
	},
	//backward navigation
	{
		'pitch': -20,
		'yaw': 138.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 21,
		}
	}
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene22 = {
	sceneId: '22',
	config: getConfig(customConfig)
};	

export default Scene22;