import { Asset11 } from 'assets/images';

const Item11 = {
	'speechTitle': 'content.informationItem.11.speechTitle',
	'speechText': 'content.informationItem.11.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.11.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.11.contentText'
		},
		{
			'type': 'image',
			'param': Asset11
		}
	],
	'infoText': 'content.informationItem.11.infoText'
};
export default Item11;
