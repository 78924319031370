import { HET_0300_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0300_render,
	yaw: -42.2480,
	pitch: -15.49432
};


let customHotspots  =	[
	//forward navigation
	// {
	// 	'pitch': -39.355,
	// 	'yaw': -41.988,
	// 	'type': 'changeScene',
	// 	'text': 'Next destination',
	// 	'createTooltipArgs': {
	// 		'text': '1',
	// 		sceneId: 1,
	// 	}
	// },
	//backward navigation
	{
		'pitch': -40,
		'yaw': -58.9,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 1,
		}
	},
	//action 2: shop window
	{
		'pitch': -6.7668,
		'yaw': -27.0399,
		'type': 'action',
		'createTooltipArgs': {
			'number': '1',
			'text':'content.hoveritems.2',
			'param': 2
		}
	
	},


];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene2 = {
	sceneId: '2',
	config: getConfig(customConfig),
};	

export default Scene2;