import { HET_0336_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0336_render,
	yaw: -16.897,
	pitch: -20.980
};

let customHotspots = [
	//forward navigation
	{
		'pitch':  3.640,
		'yaw': -22.009,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 10,
		}
	},
	//backward navigation
	// {
	// 	'pitch': -20,
	// 	'yaw': 138.9,
	// 	'type': 'changeScene',
	// 	'text': 'Next destination',
	// 	'createTooltipArgs': {
	// 		'text': '1',
	// 		sceneId: 32,
	// 	}
	// }
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene38 = {
	sceneId: '38',
	config: getConfig(customConfig)
};	

export default Scene38;