const Item6 = {
	'speechTitle': 'content.informationItem.6.speechTitle',
	'speechText': 'content.informationItem.6.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.6.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.6.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.6.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.6.contentText2'
		},
		{
			'type': 'video',
			'param': 'content.informationItem.6.video'
		}
	],
	'infoText': 'content.informationItem.6.infoText'
};
export default Item6;
