import { HET_0299_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0299_render,
	pitch: 0,
	yaw: 10
};



let customHotspots  =	[
	//navigation forward
	{
		'pitch': -19.1626,
		'yaw': 24.3310,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '3',
			sceneId: 3,
		}
	},
	//action 4: Fashion walls
	{
		'pitch':  3.8743,
		'yaw': -29.7651,
		'type': 'action',
		'createTooltipArgs': {
			'number': '3',
			'text': 'content.hoveritems.4',
			'param': 4
			
		}
	},
	//action 3: make her feel at home
	{
		'pitch': -11.9687,
		'yaw': -0.51685,
		'type': 'action',
		'createTooltipArgs': {
			'number': '2',
			'text': 'content.hoveritems.1',
			'param': 3
			
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots;

const Scene1 = {
	sceneId: '1',
	config: getConfig(customConfig),

};	

export default Scene1;