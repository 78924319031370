import { HET_0304_render } from 'assets/renders';
import { getHotspots } from '../hotspots';
import getConfig from './config';

const customConfig = {
	imageSource: HET_0304_render,
	yaw: -11.3443,
	pitch: -177.8114
};

let customHotspots = [
	//forward navigation
	{
		'pitch': -19.7935,
		'yaw': -176.4145,
		'type': 'changeScene',
		'text': 'Next destination',
		'targetYaw': -34.414,
		'targetPitch': 27.474,
		'createTooltipArgs': {
			'text': '1',
			sceneId: 8,
		}
	},
	//backward navigation
	{
		'pitch': -22.14403,
		'yaw': -6.2189,
		'type': 'changeScene',
		'text': 'Next destination',
		'createTooltipArgs': {
			'text': '1',
			sceneId: 5,
		}
	},
	//action 14: pick and mix
	{
		'pitch': 0.0957,
		'yaw': 146.1158,
		'type': 'action',
		'createTooltipArgs': {
			'number': '7',
			'text': 'content.hoveritems.5',
			'param': 14
		
		}
	},
	//action 15: kiss come back 
	{
		'pitch': -14.5816,
		'yaw': -86.33879,
		'type': 'action',
		'createTooltipArgs': {
			'number': '15',
			'text': 'content.hoveritems.7',
			'param': 15
	
		}
	},
];

customHotspots = getHotspots(customHotspots);
customConfig['hotSpots'] = customHotspots; 

const Scene4 = {
	sceneId: '4',
	config: getConfig(customConfig)
};	

export default Scene4;