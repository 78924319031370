import { Asset14 } from 'assets/images';

const Item14 = {
	'speechTitle': 'content.informationItem.14.speechTitle',
	'speechText': 'content.informationItem.14.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.14.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.14.contentText'
		},
		{
			'type': 'image',
			'param': Asset14
		}
	],
	'infoText': 'content.informationItem.14.infoText'
};
export default Item14;
