const Item12 = {
	'speechTitle': 'content.informationItem.12.speechTitle',
	'speechText': 'content.informationItem.12.speechText',
	'content': [
		{
			'type': 'title',
			'param': 'content.informationItem.12.contentTitle'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.12.contentText'
		},
		{
			'type': 'title',
			'param': 'content.informationItem.12.contentTitle2'
		},
		{
			'type': 'text',
			'param': 'content.informationItem.12.contentText2'
		},
		{
			'type': 'video',
			'param': 'content.informationItem.12.video'
		}
	],
	'infoText': 'content.informationItem.12.infoText'
};
export default Item12;
